<template>
  <div>
    <v-row v-for="(fieldValue, key) in model" :key="key">
      <template v-if="fieldIsSelectMultiple(key)">
        <v-col cols="12" class="py-1">
          <strong>{{ $t(`project.${key}`) }}:</strong>
        </v-col>
        <v-col cols="12" class="py-1">
          <v-chip-group column>
            <template v-for="(chip, index) in fieldValue">
              <v-chip
                v-if="selects[key][chip]"
                :key="`chip-${index}`"
                color="primary"
                outlined
              >
                {{ selects[key][chip] }}
              </v-chip>
            </template>
          </v-chip-group>
        </v-col>
      </template>
      <template
        v-else-if="Array.isArray(fieldValue) && !fieldIsSelectMultiple(key)"
      >
        <v-col cols="12" class="py-1">
          <strong>{{ $t(`project.${key}`) }}:</strong>
        </v-col>
        <v-col cols="12" class="py-1">
          <v-chip-group column>
            <v-chip
              v-for="(chip, index) in fieldValue"
              :key="`chip-${index}`"
              color="primary"
              outlined
            >
              {{ chip.name }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </template>
      <template v-else>
        <template v-if=" key === 'detailsTitle'">
          <v-col md="12">
           <div class="d-flex align-center">
             <h2 class="details--header-title">{{ $t(`project.${key}`) }}</h2>
             <v-btn
                 v-if="$store.getters['project/getCurrentProjectId']"
                 :loading="loading"
                 text
                 icon
                 @click="$emit('buttonEvent', true)"
                 class="ma-2"
             >
               <IconEdit v-if="!editMode && !loading" />
               <v-icon v-else color="primary">mdi-check-circle-outline</v-icon>
             </v-btn>
           </div>
          </v-col>
        </template>
        <template v-else>
          <v-col md="6" class="py-1">
            <strong>
              <template v-if=" key === 'type_of_extension' && !fieldValue"></template>
              <template v-else>
                {{ $t(`project.${key}`) }}:
              </template>

            </strong>
          </v-col>
          <v-col md="6" class="py-1">
            <template v-if=" key === 'type_of_extension' && !fieldValue"></template>
            <template v-else>{{ fieldIsSelect(key) ? selects[key][fieldValue] : fieldValue }}</template>
            {{ key === "square" ? " m2" : "" }}

          </v-col>
        </template>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SummaryOfEstimateView",
  components: {
    IconEdit: () => import("~cp/Icons/IconEdit"),
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    editMode: Boolean,
  },
  inject: ["projectDicts"],
  data() {
    return {
      selects: Object.assign({}, this.projectDicts()),
    };
  },
  created() {
    this.selects.need_an_architect = { ...this.selects.simple_choice };
    this.selects.need_an_education = { ...this.selects.simple_choice };
    this.selects.need_credit = { ...this.selects.simple_choice };
  },
  computed: {
    fieldIsSelect() {
      return (key) => {
        return key in this.selects;
      };
    },
    fieldIsSelectMultiple() {
      return (key) => {
        return key === "criterias";
      };
    },
  },
};
</script>

<style></style>
